<template>
  <div class="sb-offer-detail">
    <div class="sb-offer-detail-bar" />
    <div class="sb-offer-detail-title">
      <div>
        {{ $t('proArea.offers.offerName', { offer: plan.title }) }}
      </div>
      <div class="sb-offer-detail-title-price">
        {{ localePrice(plan.price) }} {{ plan.price_info }}
        <div v-if="plan.price_vat && showVat" class="sb-offer-detail-title-price-vat">
          <span class="tag">
            {{ $t('currency.VAT_amount') }} : {{ localePrice((plan.price_vat || 0) - (plan.price || 0)) }}
          </span>
          {{ localePrice(plan.price_vat, true, true) }}
        </div>
      </div>
    </div>
    <div class="sb-offer-detail-description">{{ plan.description }}</div>
    <div class="sb-offer-detail-date">
      {{ $t('proArea.offers.period', { startDate, endDate }) }}
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import Price from '@/mixins/price-mixin'

export default {
  name: 'SbOfferDetail',
  mixins: [Price],
  props: {
    plan: { type: Object, default: () => {} },
    showVat: { type: Boolean, default: false },
  },
  data() {
    return {
      startDate: DateTime.now().toFormat('dd.LL.yyyy'),
      endDate: DateTime.now().plus({ year: 1 }).toFormat('dd.LL.yyyy'),
    }
  },
}
</script>

<style lang="scss">
.sb-offer-detail {
  position: relative;
  padding-left: 8px;
  margin-bottom: $spacing-md;
  &-bar {
    background-color: var(--primary);
    width: 3px;
    position: absolute;
    height: 100%;
    left: -3px;
    &:before {
      background-color: var(--primary);
      border-radius: 100px;
      content: '';
      height: 6px;
      position: absolute;
      top: -3px;
      width: 6px;
      left: -1.5px;
    }
    &:after {
      background-color: var(--primary);
      border-radius: 100px;
      bottom: -3px;
      content: '';
      height: 6px;
      position: absolute;
      width: 6px;
      left: -1.5px;
    }
  }
  &-title {
    display: flex;
    font-size: pxToRem(15px);
    justify-content: space-between;
    padding-bottom: $spacing-sm;

    &-price {
      font-weight: 800;
      text-align: right;
    }
    &-price-vat {
      color: var(--steel-grey);
      font-size: pxToRem(12px);
      .tag {
        background-color: var(--bg-grey);
        padding: 0 $spacing-xxs;
        border-radius: $spacing-xxs;
        margin-right: $spacing-xs;
      }
    }
  }
  &-description {
    padding-bottom: $spacing-sm;
  }
  &-description,
  &-date {
    color: var(--steel-grey);
    font-size: pxToRem(12px);
  }
}
</style>
